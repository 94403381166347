@use '../../../scss/variables' as vars;

.userPicWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0;
  overflow: hidden;
  background-color: vars.$color-light-gray-3;
  border-radius: 50%;
  // border:1px solid red; 

  .userPicInner {
    aspect-ratio: 1 / 1;

    & img {
      aspect-ratio: 1 / 1;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
