@use '../../../scss/variables' as vars;

 .smallerImg {
  object-fit: cover;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    @media screen and (max-width: 576px) {
      border-radius: 50%;
      width: '30px';
      height: '30px';
      
    }
  }



  