@use '../../scss/variables' as vars;
.profileWrapper {
  .profilePic {
    & > div {
      // max-height: 270px;
      // max-width: 270px;
      
      
      

      // @media screen and (min-width: 768px) {
      //   margin-top: -3rem;
      // }
      // @media screen and (max-width: 576px) {
      //   max-height: 270px;

      //   margin-bottom: 7rem;
      // }

      @media screen and (min-width: 576px) {
        // margin-top: -0rem;
        // width: 270px;
        // height: 270px;
        max-width: 100%;
        margin-top: -7rem;
      }
      @media screen and (min-width: 768px) {
        margin-top: -10rem;
        width: 270px;
        height: 270px;
        max-width: 270px;
        
      }
      
      @media screen and (min-width: 992px) {
        margin-top: -5rem;
        width: 270px;
        height: 270px;
        max-width: 270px;
        
        // transform: translateY(-15%);
      }
      @media screen and (min-width: 1200px) {
        margin-top: -5rem;
        width: 270px;
        height: 270px;
        max-width: 270px;
        transform: translateX(-7px);
        // transform: translateY(-15%);
      }
    }

    .badge {
      width: 70px;
      height: 70px;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3rem;
      margin-left: -2.5rem;
    }
    .badgeOver {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      align-self: flex-start;
      justify-content: center;
      transform: translateY(-25%);
      font-size: 3rem;
      margin-left: -2.5rem;
    }
  }
}
.picUp {
  @media screen and (min-width: 768px) {
    transform: translateY(-30%);
    -ms-transform: translateY(-30%);
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    -o-transform: translateY(-30%);  
  }
  
}

.minipicUp{
  @media screen and (min-width: 768px) {
    transform: translateY(-15%);
    -ms-transform: translateY(-15%);
    -webkit-transform: translateY(-15%);
    -moz-transform: translateY(-15%);
    -o-transform: translateY(-15%);  
  }
}

.picUpPage {
  transform: translateY(-14%);
}

.picUpPortfolio {
  transform: translateY(-20px);
}

.bannerPic {
  height: 40px;
  @media screen and (max-width: 576px) {
    height: 65px;
  }
  @media screen and (max-width: 768px) {
    height: 60px;
  }
}

.badges {
  position: relative;
  margin-top: -5rem;

  .badge {
    width: 70px;
    height: 70px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    margin-left: -2.5rem;
  }
}

.badgePic {
  & > div {
    max-height: 240px;
    max-width: 195px;
    margin-bottom: 55px;
    @media screen and (max-width: 576px) {
      max-height: 250px;

      margin-bottom: 7rem;
    }
    @media screen and (min-width: 768px) {
      max-width: 250px;
      
    }
  }
}

.successBackground {
  // background-color: vars.$color-success;
  background: rgba(195, 222, 114, 0.8);

  position: absolute;
  z-index: 6;
  height: 60px;
  span {
    // border: 1px solid red;
    color: vars.$color-light;
    height: 50px !important;

    // opacity: 1 !important;
  }
  button {
    background-color: vars.$color-desall-blue !important;
    opacity: 1 !important;
  }
}

.errorBackground {
  background: rgba(222, 128, 102, 0.8);
  position: absolute;
  z-index: 6;
  height: 60px;
  span {
    color: vars.$color-light;
    height: 50px !important;
  }
  button {
    background-color: vars.$color-desall-blue !important;
    opacity: 1 !important;
  }
}

.warningBackground {
  background: rgba(242,184,69, 0.8);
  position: absolute;
  z-index: 6;
  height: 60px;
  span {
    color: vars.$color-light;
    height: 50px !important;
  }
  button {
    background-color: vars.$color-desall-blue !important;
    opacity: 1 !important;
  }
}


.ambassadorLeftImgWrapper {
  aspect-ratio: 1/1;
  // border: 1px solid red;
  .ambassadorLeftImg {
    height: 100%;
    width: 100%;
    background-color: vars.$color-light-gray-3;
    // border: 1px solid red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      @media screen and (max-width: 576px) {
        font-size: 5rem;
      }
      @media screen and (min-width: 576px) {
        font-size: 5rem;
      }
      @media screen and (min-width: 768px) {
        font-size: 5rem;
      }
      @media screen and (min-width: 992px) {
        font-size: 7rem;
      }
      @media screen and (min-width: 1200px) {
        font-size: 10rem;
      }
      @media screen and (min-width: 1400px) {
        font-size: 10rem;
      }
    }
  }
}

input::placeholder {
  text-align: center;
}

.arrow {
  display: flex;
  margin-right: 3rem;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
  // border: 1px solid red;
  .angle {
    // border: 1px solid black;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.transitionIn {
  animation-duration: 0.2s;
  animation-name: animationIn;
}

@keyframes animationIn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.divLists {
  position: relative;
}

.removeButton {
  border-radius: 8px;
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: vars.$color-danger;
  color: vars.$color-light;
  font-family: vars.$font-heading;
  font-weight: 700;
  font-size: 1.4rem;

  &:hover {
    cursor: pointer;
    color: vars.$color-light !important;
    background-color: lighten(vars.$color-danger, 20%) !important;
  }
}


.uploadCoverContainer {
  height: 200px;

  .dropzone {
    border: 0.2rem dashed vars.$color-desall-blue;
    border-radius: 8px;
    margin: 0;

    flex-grow: 1;
    width: 100%;
    height: 100%;
  }
}

.previewImg {
  height: 50px;
  width: 66.5px;
  object-fit: contain;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: vars.$color-light;
}

input[type='text'] {
  height: 30px;
  color: vars.$color-dark_grey_90;
  padding: 0px 1rem;
}


.removeButton {
  border-width: 0;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 2rem;
  }
  &:hover {
    cursor: pointer;
  }
}

.successBackground {
  // background-color: vars.$color-success;
  background: rgba(195, 222, 114, 0.8);

  position: absolute;
  z-index: 6;
  height: 60px;
  span {
    // border: 1px solid red;
    color: vars.$color-light;
    height: 50px !important;

    // opacity: 1 !important;
  }
  button {
    background-color: vars.$color-desall-blue !important;
    opacity: 1 !important;
  }
}

.errorBackground {
  background: rgba(222, 128, 102, 0.8);
  position: absolute;
  z-index: 6;
  height: 60px;
  span {
    color: vars.$color-light;
    height: 50px !important;
  }
  button {
    background-color: vars.$color-desall-blue !important;
    opacity: 1 !important;
  }
}

.warningBackground {
  background: rgba(242, 184, 69, 0.8);
  position: absolute;
  z-index: 6;
  height: 60px;
  span {
    color: vars.$color-light;
    height: 50px !important;
  }
  button {
    background-color: vars.$color-desall-blue !important;
    opacity: 1 !important;
  }
}

.translateY60 {
  transform: translateY(60px);
}

.translateY120 {
  transform: translateY(120px);
}
.removeBannerButton {
  border-radius: 8px;
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: vars.$color-desall-blue;
  opacity: 1 !important;
  color: vars.$color-light;
  font-family: vars.$font-heading;
  font-weight: 700;
  font-size: 1.4rem;
  border: 0px;
  z-index: 10;

  &:hover {
    cursor: pointer;
    color: vars.$color-light !important;
    background-color: lighten(vars.$color-desall-blue, 20%) !important;
  }
}
