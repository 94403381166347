@use '../../scss/variables' as vars;
@use '../../scss/mixins' as mixins;

.accordionItemWrapper {
  .accordionItemHandle {
    overflow: hidden;
    @include mixins.transitionall(0.2s);

    cursor: pointer;

    position: relative;

    background-color: vars.$color-light;
    border-radius: vars.$radius-m;
    height: 3.9rem;
    line-height: 1.2rem;
    padding: 0 1rem;
    font-size: vars.$font-size-xs;
    color: vars.$color-dark;

    .label {
      padding: 0.4rem 0;
      overflow-y: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 2rem;
    }

    .arrow {
      @include mixins.transitionall(0.2s);
      display: inline-block;
      padding: 3px;

      position: absolute;
      right: 0.15rem;
      top: 0.1rem;

      width: 2.2rem;
      height: 2.2rem;
      //   background-image: url("data:image/svg+xml;utf8,<svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='m17.488 8.53-5.979 5.979a.894.894 0 0 1-1.268 0L4.263 8.531A.896.896 0 1 1 5.53 7.263l5.344 5.344 5.344-5.344a.897.897 0 1 1 1.269 1.268z' fill='%23193A56' fill-rule='evenodd'/></svg>");
      //   background-repeat: no-repeat;

      &::after {
        @include mixins.transitionall(0.2s);
        position: absolute;
        display: block;
        content: '';
        width: 1.2rem;
        height: 1.2rem;
        border: 0.4rem solid vars.$color-desall-blue;
        background: vars.$color-light;
        border-top: 0;
        border-left: 0;
        right: 1rem;
        top: 1.2rem;
        transform: rotate(45deg);
        cursor: pointer;
      }
    }
  }

  .accordionItemContent {
    @include mixins.transitionall(0.4s);
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    padding-top: -2rem;
  }

  &.opened {
    .accordionItemHandle {
      .arrow {
        top: 0.5rem;
        &::after {
          transform: rotate(-135deg);
        }
      }
    }

    .accordionItemContent {
      opacity: 1;
      max-height: 100vh;
      padding-top: 2rem;
    }
  }
}
