@use '../../scss/variables' as vars;
@use '../../scss/mixins' as mixins;

.headerWrapper {
  .headerContentWrapper {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 1rem;
  }

  .headerTopRowContainer {
    // @include mixins.transitionall(0.1s);
    position: fixed;
    z-index: 10000;

    overflow: hidden;

    .headerTopRow {
      .openCloseButton {
        border: none;
        background: transparent;
      }

      .headerLogo {
        img {
          width: 100%;
          max-width: 12rem;
        }
      }
    }

    &:not(.opened) {
      height: 9rem;
    }

    &.opened {
      height: auto;
    }

    @media screen and (min-width: 992px) {
      overflow: visible;
    }
  }

  .headerTopNav {
    list-style: none;
    list-style-image: none;

    & > li {
      margin-right: 2rem;
      &:last-child {
        margin-right: 0;
      }

      a {
        font-weight: bold;
        text-decoration: none;
        color: vars.$color-light;
        &:hover {
          text-decoration: none;
          color: vars.$color-dark;
        }
      }
    }
  }

  .headerBreadCrumbs {
    a {
      font-weight: bold;
      text-decoration: none;
      color: vars.$color-light;
      &:hover {
        text-decoration: none;
        color: vars.$color-dark;
      }
    }
  }
}

.profileWrapper {
  .profilePic {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
  }

  .profileActions {
    @include mixins.transitionall(0.2s);
    position: relative;
    // border: 1px solid red;

    .profileName,
    .profileMail,
    .profileLink {
      color: vars.$color-light;
    }

    @media screen and (min-width: 992px) {
      position: absolute;

      right: 0;
      height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 30rem;
      // border: 1px solid red;

      top: 0rem;
      max-height: 0;
      opacity: 0;

      border-radius: vars.$radius-m;
      background-color: vars.$color-light;

      &::after {
        position: absolute;
        display: block;
        content: '';
        top: -0.4rem;
        right: 1.3rem;
        // border: 1px solid red;
        opacity: 1;
        transform: rotate(-54deg) skew(-15deg);
        content: 0;
        background-color: vars.$color-light;
        width: 2rem;
        height: 2rem;
      }

      .profileName {
        color: vars.$color-desall-blue;
      }

      .profileMail {
        color: vars.$color-light-gray-4;
      }

      .profileLink {
        color: vars.$color-dark;
      }
    }
  }

  &:hover {
    cursor: pointer;
    .profileActions {
      @media screen and (min-width: 992px) {
        overflow: visible;
        top: 5.5rem;
        max-height: 30rem;
        opacity: 1;
      }
    }
  }
}
