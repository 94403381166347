@use '../scss/variables' as vars;
@use '../scss/mixins' as mixins;

.Modal {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  // background-color: red;
}

.rounded {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
