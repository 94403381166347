@use '../../../scss/variables' as vars;

.layoutSwitch {
  border-radius: vars.$radius-s;
}



.paginationSwitch {
  border-radius: vars.$radius-s;
}

