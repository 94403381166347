@use '../../scss/variables' as vars;
@use '../../scss/mixins' as mixins;

$timeline-height: 3.4rem;

.timelineContainer {
  .timelineWrapper {
    height: $timeline-height;
    background-color: vars.$timeline-gray-3;
    border-radius: vars.$radius-m;
    overflow: hidden;

    &.closed {
      .endSection {
        background-color: vars.$color-danger;
      }
    }
  }

  .progressWrapper {
    position: relative;

    .progressBar {
      height: $timeline-height;
    }

    .progressLabel {
      @include mixins.transitionall(0.2s);
      position: absolute;
      height: 9rem;
      top: -3.6rem;
      right: 0;
      width: 15rem;

      text-transform: uppercase;

      .progressSub {
        font-size: 1rem;
      }
    }

    .progressIcon {
      position: absolute;
      height: $timeline-height;
      top: 0;
      left: 0;
      text-transform: lowercase;
      z-index: 10;
      width: 100%;
    }

    &.upload {
      background-color: vars.$timeline-gray-1;

      .progressBar {
        background-color: vars.$color-success;
        @include mixins.transitionall(0.2s);
      }
    }

    &.community {
      min-width: 3rem;
      background-color: vars.$timeline-gray-4;

      .progressBar {
        background-color: vars.$color-warning;
        @include mixins.transitionall(0.2s);
      }
    }

    &.client {
      min-width: 3rem;
      background-color: vars.$timeline-gray-2;

      .progressBar {
        background-color: vars.$color-warning;
        @include mixins.transitionall(0.2s);
      }
    }

    &.closed {
      min-width: 3rem;
      background-color: vars.$timeline-gray-2;

      .progressBar {
        background-color: vars.$color-danger;
        @include mixins.transitionall(0.2s);
      }
    }
  }
}
