@use '../../../scss/variables' as vars;

.card {
  padding: 15px;
}

.cardInfoWrapper {
  border-radius: vars.$radius-s;
  overflow: hidden;
  align-self: stretch;
  min-height: 100%;

  .cardInfoImage {
    img {
      object-fit: cover;
      width: 100%;
      // height: 25rem;
    }
  }

  .cardImagePlaceholder {
    // height: 27.5rem;
    background-color: vars.$color-light-gray-4;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8rem;
  }

  .cardInfoContent {
    padding: 1rem;
    height: 270px;
    .infoContainer {
      padding-left: 15%;
      
    }
  }

  .squaredImageWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;
    overflow: hidden;
    width: 100%;

    .imageInner {
      min-width: 100%;
      aspect-ratio: 1 / 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
