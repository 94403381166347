$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
  xxl: 1170px,
) !default;

$grid-breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

.splitSectionImageWrapper {
  position: relative;
  .halfSection {
    width: 100%;
    max-width: 100%;

    @each $breakpoint, $min-width in $grid-breakpoints {
      @media screen and (min-width: $min-width) {
        @if $min-width == 576px {
          max-width: 100%;
        } @else {
          $container-width: map-get($container-max-widths, $breakpoint);
          max-width: $container-width/2;
        }
      }
    }
  }

  .splitSectionContentWrapper {

    display: flex;
    flex-direction: row;

    &.reverse{
  
      flex-direction: column-reverse;

      @media screen and (min-width: 576px) {
        flex-direction: row;
       }
    }

    .contentSectionWrapper {
      padding-left: 1rem;

      @media screen and (min-width: 992px) {
        padding-left: 10rem;
      }
    }

    .imgSectionWrapper {
      .imgSection {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        object-fit: contain;


        min-height: 30rem;
      }
    }

    &.skewed {
      .contentSectionWrapper {
        transform: skew(15deg);
        padding-left: 10rem;
        margin-left: -5rem;

        .contentSection {
          transform: skew(-15deg);
        }
      }

      .imgSectionWrapper {
        transform: skew(15deg);
        .imgSection {
          transform: skew(-15deg);
          width: 120%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: left center;
          background-size: cover;
        }
      }

      &.reverse {      

        .contentSectionWrapper {
          z-index: 2;
          transform: skew(-15deg);
          padding-right: 0;
          margin-right: 0;

          .contentSection {
            transform: skew(15deg);
          }
        }

        .imgSectionWrapper {
          z-index: 1;
          transform: skew(0);
          margin-left: -8rem;

          .imgSection {
            transform: skew(0);
            width: 120%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: left center;
            background-size: cover;
          }
        }
      }
    }
  }
}
