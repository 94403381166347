@use '../../../../scss/variables' as vars;
@use '../../../../scss/mixins' as mixins;

.uploadContainer {
  width: 100%;
  padding: 0;
  min-height: 100%;
  background-color: transparent;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mixins.transitionall(0.2s);

  // &.inProgress {
  //   border-radius: 50%;
  //   border: 0.2rem dashed vars.$color-desall-blue;
  // }

  .dropArea {
    border: 0.2rem dashed vars.$color-desall-blue;
    border-radius: 50%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    flex-grow: 1;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 576px) {
    }
    @media screen and (min-width: 576px) {
    }
    @media screen and (min-width: 768px) {
      transform: translate(0px, -5px);
    }
    @media screen and (min-width: 992px) {
      transform: translate(0px, 0px);
    }
    @media screen and (min-width: 992px) {
      transform: translate(0px, 0px);
    }
    .addButton {
      border-width: 0;
      border-radius: 50%;
      width: 6rem;
      height: 5rem;

      @media screen and (max-width: 576px) {
      }
      @media screen and (min-width: 576px) {
      }
      @media screen and (min-width: 768px) {
        width: 5rem;
        height: 5rem;
      }
      @media screen and (min-width: 992px) {
        width: 6rem;
        height: 6rem;
      }
      @media screen and (min-width: 1200px) {
        width: 6rem;
        height: 6rem;
      }

      svg {
        font-size: 4rem;
        line-height: 6rem;
        @media screen and (min-width: 768px) {
          font-size: 3rem;
        }
        @media screen and (min-width: 992px) {
          font-size: 4rem;
        }
        @media screen and (min-width: 1200px) {
          font-size: 4rem;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .progressArea {
    width: 100%;
    min-height: 16rem;
    padding: 0rem 6rem;
    padding-bottom: 12%;
  }
}

.progressBarWrapper {
  
  height: 2.5rem;
  width: 100%;
  border-radius: vars.$radius-s;
  background-color: vars.$color-light-gray-2;
  overflow: hidden;

  .progressBar {
    @include mixins.transitionall(0.5s);
    transition-delay: 0.5s;
    height: 2.5rem;
    background-color: vars.$color-desall-blue;
  }
}

.cancelButton {
  border: 0;
  background-color: transparent;
}

.uploadPreviewImg {
  background-color: vars.$color-light-gray-4;
  width: 100%;
  height: 10rem;
  border-radius: 0.5rem;
  display: block;
  object-fit: cover;
}
