@use '../../scss/variables' as vars;

.bigImgContainer {
  height: 650px;
  width: 100%;
  border-radius: 15px;
  background-color: vars.$color-light-gray-5;
}

.chevron {
  color: vars.$color-desall-blue;
  font-size: 1.4rem;
}

.smallImgContainer {
  height: 70px;
  width: 14%;
  border-radius: 10px;
  background-color: vars.$color-light-gray-5;
}

.smallerImg {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  @media screen and (max-width: 576px) {
    border-radius: 50%;
    width: '30px';
    height: '30px';
  }
}

.myswiper {
  position: relative;
  width: 100%;
  aspect-ratio: 4/3;
  border-radius: 8px;
  background-color: vars.$color-light-grey-30;
  @media screen and (max-width: 576px) {
    width: 100%;
    height: 22%;
  }
  .swiperslide {
    background-color: white;
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }
  .swiperNavNext {
    top: 0;
    position: absolute;
    // transform: translateY(-50%);
    width: 30px;
    height: 30px;
    z-index: 3;
    cursor: pointer;
    right: 1px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .swiperTrophy {
    top: 0%;
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .trophyDiv {
    height: 50px;
    width: 40px;

    padding: 10px;
    background-color: #55b9b9;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    color: vars.$color-light;
  }

  .nextButtonContainer {
    z-index: 1;
    top: 0;
    right: 0;
    height: 100%;
    width: 50px;
    // border: 1px solid red;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .nextButton {
      height: 50px;
      width: 100%;
      border: 1px solid blue;
    }
  }

  .prevButtonContainer {
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 50px;
    // border: 1px solid red;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .prevButton {
      height: 50px;
      width: 100%;
      border: 1px solid blue;
    }
  }
}
.thumbnaildiv {
  width: auto;
  max-height: 120px;
  object-fit: cover;
  border-radius: 8px;
  background-color: vars.$color-light-grey-30;

  @media screen and (max-width: 576px) {
    width: 5.5vh;
    aspect-ratio: 4/3;
    border-radius: 8px;
  }
  @media screen and (min-width: 576px) {
    width: 6.5vh;
    aspect-ratio: 4/3;
    border-radius: 8px;
  }
  @media screen and (min-width: 768px) {
    width: 9vh;
    aspect-ratio: 4/3;
    border-radius: 8px;
  }
  @media screen and (min-width: 992px) {
    width: 8.5vh;
    aspect-ratio: 4/3;
    border-radius: 8px;
  }
  @media screen and (min-width: 1200px) {
    width: 10.5vh;
    aspect-ratio: 4/3;
    border-radius: 8px;
  }
  @media screen and (min-width: 1400px) {
    width: 10.5vh;
    aspect-ratio: 4/3;
    border-radius: 8px;
  }

  .thumbnailimg {
    background-color: white;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    border-radius: 8px;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .thumbnailimg:hover {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}

.border {
  opacity: 0.3;
  filter: alpha(opacity=40);
}

.mybigswiper {
  position: relative;
  width: 100%;
  aspect-ratio: 4/3;
  background-color: vars.$color-light-grey-30;
  border-radius: 8px;
  @media screen and (max-width: 576px) {
    width: 100%;
    height: 100%;
  }

  .swiperslide {
    background-color: white;
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }
  .swiperNavNext {
    top: 0%;
    position: absolute;
    // transform: translateY(-50%);
    align-items: center;
    width: 30px;
    height: 30px;
    z-index: 3;
    cursor: pointer;
    right: 0;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .swiperTrophy {
    top: 0%;
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .trophyDiv {
    height: 70px;
    width: 60px;

    padding: 10px;
    background-color: #55b9b9;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    color: vars.$color-light;
  }

  .nextButtonContainer {
    z-index: 1;
    top: 0;
    right: 0;
    height: 100%;
    width: 50px;
    // border: 1px solid red;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .nextButton {
      height: 50px;
      width: 100%;
      border: 1px solid blue;
    }
  }

  .prevButtonContainer {
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 50px;
    // border: 1px solid red;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .prevButton {
      height: 50px;
      width: 100%;
      border: 1px solid blue;
    }
  }
}

.thumbnaildivBig {
  width: auto;
  object-fit: cover;

  border-radius: 8px;

  @media screen and (max-width: 576px) {
    width: 6.5vh;
    aspect-ratio: 4/3;
    border-radius: 8px;
  }
  @media screen and (min-width: 576px) {
    width: 6.5vh;
    aspect-ratio: 4/3;
    border-radius: 8px;
  }
  @media screen and (min-width: 768px) {
    width: 9vh;
    aspect-ratio: 4/3;
    border-radius: 8px;
  }
  @media screen and (min-width: 992px) {
    width: 12.5vh;
    aspect-ratio: 4/3;
    border-radius: 8px;
  }
  @media screen and (min-width: 1200px) {
    width: 15.5vh;
    aspect-ratio: 4/3;
    border-radius: 8px;
  }
  @media screen and (min-width: 1400px) {
    width: 15vh;
    aspect-ratio: 4/3;
    border-radius: 8px;
  }

  .thumbnailimg {
    background-color: white;
    // border: 1px solid red;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    border-radius: 8px;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .thumbnailimg:hover {
    width: 100%;
    height: 100%;
    opacity: 1;
    // border: 1px solid red;
  }
}


.removeButton {
  border-width: 0;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 2rem;
  }
  &:hover {
    cursor: pointer;
  }
}

.removeBannerButton {
  border-radius: 8px;
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: vars.$color-desall-blue;
  color: vars.$color-light;
  font-family: vars.$font-heading;
  font-weight: 700;
  font-size: 1.4rem;
  border: 0px;

  &:hover {
    cursor: pointer;
    color: vars.$color-light !important;
    background-color: lighten(vars.$color-desall-blue, 20%) !important;
  }
}

.successBackground {
  // background-color: vars.$color-success;
  background: rgba(195, 222, 114, 0.8);

  position: absolute;
  z-index: 6;
  height: 60px;
  span {
    // border: 1px solid red;
    color: vars.$color-light;
    height: 50px !important;

    // opacity: 1 !important;
  }
  button {
    background-color: vars.$color-desall-blue !important;
    opacity: 1 !important;
  }
}

.errorBackground {
  background: rgba(222, 128, 102, 0.8);
  position: absolute;
  z-index: 6;
  height: 60px;
  span {
    color: vars.$color-light;
    height: 50px !important;
  }
  button {
    background-color: vars.$color-desall-blue !important;
    opacity: 1 !important;
  }
}

.warningBackground {
  background: rgba(242, 184, 69, 0.8);
  position: absolute;
  z-index: 6;
  height: 60px;
  span {
    color: vars.$color-light;
    height: 50px !important;
  }
  button {
    background-color: vars.$color-desall-blue !important;
    opacity: 1 !important;
  }
}

.translateY60 {
  transform: translateY(60px);
}

.translateY120 {
  transform: translateY(120px);
}
.removeBannerButton {
  border-radius: 8px;
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: vars.$color-desall-blue;
  opacity: 1 !important;
  color: vars.$color-light;
  font-family: vars.$font-heading;
  font-weight: 700;
  font-size: 1.4rem;
  border: 0px;
  z-index: 10;

  &:hover {
    cursor: pointer;
    color: vars.$color-light !important;
    background-color: lighten(vars.$color-desall-blue, 20%) !important;
  }
}