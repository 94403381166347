@use '../../../../scss/variables' as vars;

.card {
  // padding: 15px;
}

.cardInfoWrapper {
  border-radius: vars.$radius-s;
  overflow: hidden;
  align-self: stretch;
  // min-height: 100%;
  // height: 540px;
  // width: 270px;
  @media screen and (min-width: 576px) {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    max-width: 320px;
  }
  @media screen and (min-width: 992px) {
    width: 270px;
    height: 540px;

    max-width: 270px;
  }

  .cardInfoImage {
    // border: 1px solid red;
    @media screen and (min-width: 576px) {
      max-height: 100%;
    }
    @media screen and (min-width: 768px) {
      max-height: 320px;
    }
    @media screen and (min-width: 992px) {
      max-height: 270px;
      // transform: translateY(-15%);
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .draft {
      z-index: 3;
      height: 50px;
      // border: 1px solid blue;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: vars.$color-danger;
      opacity: 0.95;

      @media screen and (min-width: 576px) {
        max-height: 100%;
      }
      @media screen and (min-width: 768px) {
        max-height: 320px;
        transform: translateY(-50px);
      }
      @media screen and (min-width: 992px) {
        max-height: 270px;
        transform: translateY(-50px);
        
      }

      span {
        color: white;
        font-weight: 800;
        font-size: 2.5rem;
        font-family: vars.$font-body;
      }
    }
  }

  .cardImagePlaceholder {
    // height: 27.5rem;
    background-color: vars.$color-light-gray-4;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8rem;
    // width: 270px;
    // height: 270px;
  }

  .cardInfoContent {
    padding: 1rem;
    height: 270px;
    .infoContainer {
      @media screen and (min-width: 576px) {
        padding-left: 25%;
        overflow: hidden;
        // transform: translateY(-15%);
      }
      @media screen and (min-width: 768px) {
        padding-left: 15%;
        overflow: hidden;
        // transform: translateY(-15%);
      }
    }
  }

  .squaredImageWrapper {
    // display: grid;
    // grid-template-columns: 1fr;
    // grid-gap: 0;
    // overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;
    overflow: hidden;
    width: 100%;

    .imageInner {
      min-width: 100%;
      aspect-ratio: 1 / 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .draft {
      z-index: 3;
      height: 50px;
      // border: 1px solid blue;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: vars.$color-danger;
      opacity: 0.95;
      @media screen and (min-width: 992px) {
        transform: translateY(-50px);
        // transform: translateY(-15%);
      }
      span {
        color: white;
        font-weight: 800;
        font-size: 2.5rem;
        font-family: vars.$font-body;
      }
    }
  }
}
