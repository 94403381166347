@use '../../../scss/variables' as vars;

.closeButton {
  height: 30px;
  width: 30px;
  background-color: vars.$color-danger;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.closeButton:hover {
  opacity: 0.7;
}

.rounded {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: 0px;
}
