@use '../../../scss/variables' as vars;

.statsWrapper {
  & > div {
    border-right: 1px solid vars.$color-desall-blue;
  }

  & > div:nth-child(2n) {
    border-right: 0;

    @media screen and (min-width: 992px) {
      border-right: 1px solid vars.$color-desall-blue;
    }
  }

  & > div:nth-child(4n) {
    @media screen and (min-width: 992px) {
      border-right: 0;
    }
  }
}
