@use '../../../../scss/variables' as vars;

$row-height: 9rem;

.contestRowWrapper {
  border-radius: vars.$radius-s;
  overflow: hidden;
  height: auto;

  @media screen and (min-width: 992px) {
    height: 9rem;
  }

  .contestRowImage {
    overflow: hidden;
    border-radius: vars.$radius-s;
    width: $row-height;
    height: $row-height;

    img {
      width: $row-height;
      height: $row-height;
      object-fit: cover;
    }
  }

  .contestRowImagePlaceholder {
    border-radius: vars.$radius-s;
    height: $row-height;
    width: $row-height;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
  }

  .statusBar {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .contestRowGallery {
    overflow: hidden;
    border-radius: vars.$radius-s;
    height: $row-height;

    & > div:first-child {
      overflow: hidden;
      border-radius: 0 0 0 vars.$radius-s;

      @media screen and (min-width: 992px) {
        border-radius: vars.$radius-s 0 0 vars.$radius-s;
      }
    }

    & > div:last-child {
      overflow: hidden;
      border-radius: 0 0 vars.$radius-s 0;

      @media screen and (min-width: 992px) {
        border-radius: 0 vars.$radius-s vars.$radius-s 0;
      }
    }

    img {
      width: 100%;
      height: $row-height;
      object-fit: cover;

      @media screen and (min-width: 992px) {
        width: $row-height;
      }
    }

    .contestRowGalleryItem {
      height: $row-height;
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;

      @media screen and (min-width: 992px) {
        width: $row-height;
      }
    }
  }
}
