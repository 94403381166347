@use '../../scss/variables' as vars;
@import '../../scss/mixins';

.navigationWrapper {
  .tabsWrapper {
    overflow-x: auto;
    scroll-snap-align: start;
    display: flex;
    flex-wrap: wrap;
    height: 30px;
    margin-left: 7px;
    width: 95%;

    // scrollbar-width: none;
    // &::-webkit-scrollbar {
    //   width: 0px;
    //   height: 0px;
    //   display: none;
    //   background: transparent; /* make scrollbar transparent */
    // }

    .pageTab {
      @include transitionall(0.2s);
      padding: 5px;
      
      text-decoration: none;
      color: vars.$color-dark;

      &:hover {
        color: vars.$color-dark;
        text-decoration: none;
        background-color: vars.$color-light-gray;
        border-radius: vars.$radius-m vars.$radius-m 0 0;
      }
    }

    .activeTab {
      color: vars.$color-dark;
      text-decoration: none;
      background-color: vars.$color-light-gray;
      border-radius: vars.$radius-m vars.$radius-m 0 0;
    }
  }
}
.fixed {
  position: fixed;
  // border: 1px solid transparent;
  top: 9rem;
  z-index: 500;
}

.navigationWrapperBrief {
  // border: 1px solid transparent;
  .tabsWrapperBrief {
    overflow-x: auto;
    

    scroll-snap-align: start;
    display: flex;
    flex-wrap: wrap;
    height: 30px;
    margin-left: 3px;
    width: 70%;
    // scrollbar-width: none;
    // &::-webkit-scrollbar {
    //   width: 0px;
    //   height: 0px;
    //   display: none;
    //   background: transparent; /* make scrollbar transparent */
    // }
    @media screen and (max-width: 576px) {
      width: 90%;
    }

    .pageTabBrief {
      
      @include transitionall(0.2s);
      padding: 5px;
      text-decoration: none;
      color: vars.$color-dark;

      &:hover {
        color: vars.$color-dark;
        text-decoration: none;
        background-color: vars.$color-light-gray;
        border-radius: vars.$radius-m vars.$radius-m 0 0;
      }
    }


    .disabledLinks {
      pointer-events: none;
      opacity: 0.5;
      border-radius: vars.$radius-m vars.$radius-m 0 0;
    }

    .activeTabBrief {
      color: vars.$color-dark;
      text-decoration: none;
      background-color: vars.$color-light-gray;
      border-radius: vars.$radius-m vars.$radius-m 0 0;
    }
  }
}

.navigationWrapperProfile {
  .tabsWrapperProfile {
    overflow-x: auto;

    scroll-snap-align: start;
    display: flex;
    flex-wrap: wrap;
    height: 30px;
    // margin-left: 3px;
    width: 100%;
    // scrollbar-width: none;
    // &::-webkit-scrollbar {
    //   width: 0px;
    //   height: 0px;
    //   display: none;
    //   background: transparent; /* make scrollbar transparent */
    // }
    @media screen and (max-width: 576px) {
      width: 90%;
    }

    .pageTabProfile {
      @include transitionall(0.2s);
      padding: 5px;
      text-decoration: none;
      color: vars.$color-dark;

      &:hover {
        color: vars.$color-dark;
        text-decoration: none;
        background-color: vars.$color-light-gray;
        border-radius: vars.$radius-m vars.$radius-m 0 0;
      }
    }

    .activeTabProfile {
      color: vars.$color-dark;
      text-decoration: none;
      background-color: vars.$color-light-gray;
      border-radius: vars.$radius-m vars.$radius-m 0 0;
    }
  }
}


.navigationWrapperSearch {
  // border: 1px solid transparent;
  .tabsWrapperSearch {
    overflow-x: auto;
    

    scroll-snap-align: start;
    display: flex;
    flex-wrap: wrap;
    height: 30px;
    margin-left: 3px;
    width: 100%;
    // scrollbar-width: none;
    // &::-webkit-scrollbar {
    //   width: 0px;
    //   height: 0px;
    //   display: none;
    //   background: transparent; /* make scrollbar transparent */
    // }
    @media screen and (max-width: 576px) {
      width: 90%;
    }

    .pageTabSearch {
      
      @include transitionall(0.2s);
      padding: 5px;
      text-decoration: none;
      color: vars.$color-dark;

      &:hover {
        color: vars.$color-dark;
        text-decoration: none;
        background-color: vars.$color-light-gray;
        border-radius: vars.$radius-m vars.$radius-m 0 0;
      }
    }


    .disabledLinks {
      pointer-events: none;
      opacity: 0.5;
      border-radius: vars.$radius-m vars.$radius-m 0 0;
    }

    .activeTabSearch {
      color: vars.$color-dark;
      text-decoration: none;
      background-color: vars.$color-light-gray;
      border-radius: vars.$radius-m vars.$radius-m 0 0;
    }
  }
}