@use '../../scss/variables' as vars;
@use '../../scss/mixins' as mixins;

@mixin labelSittingRight {
  transform: translateX(130%);
  margin-right: -1rem;
  padding: 0 2rem 0 1rem;
  border-top-left-radius: vars.$radius-s;
  border-bottom-left-radius: vars.$radius-s;
}

@mixin labelSittingLeft {
  transform: translateX(-130%);
  margin-left: -1rem;
  padding: 0 1rem 0 2rem;
  border-top-right-radius: vars.$radius-s;
  border-bottom-right-radius: vars.$radius-s;
}

.actionButtonWrapper {
  overflow: hidden;
  @include mixins.transitionall(0.1s);
  margin-bottom: 1px;
  cursor: pointer;
  position: absolute;
  height: 30px;
  display: flex;
  z-index: 100;
  @media screen and (max-width: 576px) {
    height: 40px;
  }

  .actionButton {
    color: vars.$color-dark;
    background-color: vars.$color-desall-blue;
    border: 0;
    cursor: pointer;
    border-radius: vars.$radius-s;
    width: 30px;
    height: 30px;

    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: vars.$color-light;
    z-index: 10;
    @media screen and (max-width: 576px) {
      width: 40px;
      height: 40px;
    }
  }

  &:not(.hasAction) {
    cursor: pointer;
    .actionButton {
      cursor: pointer;
    }
  }

  & .buttonLabel {
    @include mixins.transitionall(0.1s);

    display: flex;
    align-items: center;
    background-color: vars.$color-light-gray-2;
    height: 3rem;
    line-height: 2.6rem;

    opacity: 0;
    z-index: 1;
    //width: 100%;
  }

  &:hover {
    & .buttonLabel {
      opacity: 1;
      transform: translate(0) !important;
    }
  }

  &.topRight {
    top: 0;
    right: 0;

    & .buttonLabel {
      @include labelSittingRight;
    }
  }

  &.bottomRight {
    bottom: 0;
    right: 0;

    & .buttonLabel {
      @include labelSittingRight;
    }
  }

  &.topLeft {
    top: 0;
    left: 0;

    & .buttonLabel {
      @include labelSittingLeft;
    }
  }

  &.bottomLeft {
    bottom: 0;
    left: 0;

    & .buttonLabel {
      @include labelSittingLeft;
    }
  }
}

// Multiple buttons ==========================================================

@mixin buttonsSittingRight {
  .actionButtonWrapper {
    .actionButton {
      border-radius: vars.$radius-s 0 0 vars.$radius-s;
    }
  }
  &.rounded .actionButtonWrapper:first-child {
    .actionButton {
      border-radius: vars.$radius-s vars.$radius-s 0 vars.$radius-s;
    }
  }
  &.rounded .actionButtonWrapper:last-child {
    .actionButton {
      border-radius: vars.$radius-s 0 vars.$radius-s vars.$radius-s;
    }
    margin-bottom: 0;
  }
}

@mixin buttonsSittingLeft {
  .actionButtonWrapper {
    .actionButton {
      border-radius: 0 vars.$radius-s vars.$radius-s 0;
    }
  }
  &.rounded .actionButtonWrapper:first-child {
    .actionButton {
      border-radius: vars.$radius-s vars.$radius-s vars.$radius-s 0;
    }
  }
  &.rounded .actionButtonWrapper:last-child {
    .actionButton {
      border-radius: 0 vars.$radius-s vars.$radius-s vars.$radius-s;
    }
    margin-bottom: 0;
  }

  .actionButtonWrapper:last-child {
    margin-bottom: 0;
  }
}

.multiActionButtonsWrapper {
  position: absolute;
  z-index: 100;

  .multiActionButtonsContainer {
    position: relative;
    display: flex;
    flex-direction: column;

    .actionButtonWrapper {
      position: relative;
    }
  }

  &.wrapperTopRight {
    top: 0;
    right: 0;

    .multiActionButtonsContainer {
      align-items: flex-end;
      justify-content: flex-start;

      @include buttonsSittingRight;
    }
  }
  &.wrapperBottomRight {
    bottom: 0;
    right: 0;

    .multiActionButtonsContainer {
      @include buttonsSittingRight;
    }
  }
  &.wrapperTopLeft {
    top: 0;
    left: 0;

    .multiActionButtonsContainer {
      align-items: flex-start;
      justify-content: flex-start;
      @include buttonsSittingLeft;

      & .actionButtonWrapper {
        flex-direction: row !important;

        & .buttonLabel {
          @include labelSittingLeft;
        }
      }
    }
  }
  &.wrapperBottomLeft {
    bottom: 0;
    left: 0;

    .multiActionButtonsContainer {
      align-items: flex-start;
      justify-content: flex-start;

      @include buttonsSittingLeft;

      & .actionButtonWrapper {
        flex-direction: row !important;

        & .buttonLabel {
          @include labelSittingLeft;
        }
      }
    }
  }
}
