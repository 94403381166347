@use '../../../scss/variables' as vars;

.statusBar {
  height: 0.8rem;
  width: 100%;

  .progressBar {
    height: 100%;
  }

  &.standby {
    background-color: vars.$color-light-gray-5;
  }

  &.ongoing {
    background-color: rgba(vars.$color-success, 0.4);
    .progressBar {
      background-color: vars.$color-success;
    }
  }
  &.community_vote {
    background-color: rgba(vars.$color-dark_azure, 0.4);
    .progressBar {
      background-color: vars.$color-dark_azure;
    }
  }
  &.client_vote {
    background-color: rgba(vars.$color-warning, 0.4);
    .progressBar {
      background-color: vars.$color-warning;
    }
  }
  &.closed {
    background-color: vars.$color-danger;
  }
}
