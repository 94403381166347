@use '../../scss/variables' as vars;
@use '../../scss/mixins' as mixins;

.footerWrapper {
  //TODO remove
  z-index: 600;
  overflow: hidden;
  background-position: top center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;

  .footerContentWrapper {
    z-index: 600;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    
    .contactIconWrapper {
      min-width: 3rem;
    }
  }

  .footerSocialWrapper {
    background: rgba(vars.$color-light, 0.2);
  }

  .footerLogo {
    img {
      display: block;
      width: 100%;
      max-width: 15rem;
    }
  }

  .footerNav {
    z-index: 600;
    
    ul {
      @include mixins.transitionall(0.3s);
      list-style: none;
      list-style-image: none;
      margin: 0;
      padding: 0;

      max-height: 0;
      overflow: hidden;

      & > li {
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
            color: vars.$color-light;
          }
        }
      }

      &:not(.opened) {
        max-height: 0;
      }
      &.opened {
        max-height: 100vh;
      }

      @media screen and (min-width: 576px) {
        max-height: 100vh;

        &:not(.opened) {
          max-height: 100vh;
        }
        &.opened {
          max-height: 100vh;
        }
      }
    }
  }
}
