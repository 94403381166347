@use '../../scss/variables' as vars;
@import '../../scss/mixins';
.selected {
  background: rgba(vars.$color-desall-blue, 0.6);

  &:hover {
    .check {
      @media screen and (min-width: 300px) {
        transform: translateY(4vh);
      }
      @media screen and (min-width: 576px) {
        transform: translateY(3.9vh);
      }
      @media screen and (min-width: 765px) {
        transform: translateY(1.6vh);
      }
      @media screen and (min-width: 992px) {
        transform: translateY(2.8vh);
      }
      @media screen and (min-width: 1200px) {
        transform: translateY(3vh);
      }
    }
  }
}
.cardWrapper {
  width: 100%;
  height: 37rem;
  position: relative;

  border-radius: 8px;
  //   overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  &:hover {
    span {
      display: none;
    }
  }

  @media screen and (min-width: 576px) {
  }
  @media screen and (min-width: 768px) {
    width: 30rem;
    height: 32rem;
  }
  @media screen and (min-width: 992px) {
  }
  @media screen and (min-width: 1200px) {
    width: 35rem;
    height: 37rem;
  }
  @media screen and (min-width: 1400px) {
  }

  img {
    border-radius: 8px;
    display: block;
    object-fit: cover;
  }

  .cardOverlay {
    // @include transitionall(0.2s);
    width: 100%;
    height: 100%;
    background: rgba(vars.$color-desall-blue, 0.9);
    padding: 3px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    @media screen and (min-width: 576px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (min-width: 768px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (min-width: 992px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (min-width: 1200px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (min-width: 1300px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (min-width: 1400px) {
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    img {
      overflow: hidden;
      filter: blur(0.3rem);
    }
    cursor: pointer;
    .cardOverlay {
      opacity: 1;
      border-radius: 8px;
      span {
        display: block;
      }
    }
  }

  &.visibleContent {
    @media screen and (min-width: 576px) {
    }
    @media screen and (min-width: 768px) {
      width: 30rem;
      height: 32rem;
    }
    @media screen and (min-width: 992px) {
    }
    @media screen and (min-width: 1200px) {
      // width: 35rem;
      // height: 37rem;
      height: 100%;
      width: 100%;
    }
    @media screen and (min-width: 1400px) {
      height: 100%;
      width: 100%;
    }
    .cardOverlay {
      opacity: 1;
    }

    &:hover {
      img {
        overflow: hidden;
        filter: none;
      }

      cursor: pointer;
      .cardOverlay {
        opacity: inherit;
        span {
          display: relative;
        }
      }
    }
  }

  .check {
    @media screen and (min-width: 300px) {
      transform: translateY(3vh);
    }
    @media screen and (min-width: 576px) {
      transform: translateY(2.8vh);
    }
    @media screen and (min-width: 765px) {
      transform: translateY(-0vh);
    }
    @media screen and (min-width: 992px) {
      transform: translateY(1.8vh);
    }
    @media screen and (min-width: 1200px) {
      transform: translateY(2vh);
    }
  }
}
