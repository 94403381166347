@use '../../../scss/variables' as vars;

.designUserCardWrapper {
  height: 540px;
  width: 270px;

  .profilePic {
    & > div {
      max-height: 270px;
      max-width: 270px;
      @media screen and (max-width: 576px) {
        max-height: 270px;

        margin-bottom: 7rem;
      }
      @media screen and (min-width: 768px) {
        margin-top: -3rem;
        width: '270px';
        height: '270px';
        max-width: 270px;
      }
    }

    .badge {
      width: 40px;
      height: 40px;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      margin-left: -2.5rem;
    }
    .badgeOver {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      align-self: flex-start;
      justify-content: center;
      transform: translateY(-25%);
      font-size: 2rem;
      margin-left: -2.5rem;
    }
  }
}
