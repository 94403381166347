$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
  xxl: 1170px,
) !default;

$grid-breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

.anchor {
  z-index: 10;

  @media screen and (min-width: 768px) {
    max-width: 270px;
    position: fixed;
    top: 15rem;
    margin-top: 0 !important;
  }

  @each $breakpoint, $min-width in $grid-breakpoints {
    @if $min-width >= 768px {
      @media screen and (min-width: $min-width) {
        $container-width: map-get($container-max-widths, $breakpoint);
        width: calc($container-width/100 * 23.3);
      }
    }
  }
}
