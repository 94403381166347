@use '../../../scss/variables' as vars;

.layoutSwitch {
  border-radius: vars.$radius-s;
  width: 70px;
}

.paginationSwitch {
  border-radius: vars.$radius-s;
}
