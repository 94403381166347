@mixin transitionall($duration) {
  -webkit-transition: all $duration ease-in-out;
  -moz-transition: all $duration ease-in-out;
  -o-transition: all $duration ease-in-out;
  transition: all $duration ease-in-out;
}

@mixin customScrollbar($size: 1rem,
$foreground-color: #999,
$background-color: #333){
   // Chrome & Safari
   &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }
  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 0.8rem;
  }
  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 0.8rem;
  }
  // &::-webkit-scrollbar-corner {
  //   background: rgba(0,0,0,0);
  //   color: transparent;
  // }

  // Standard version (Firefox only for now)
  scrollbar-color: $foreground-color $background-color;
}