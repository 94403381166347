.splitSectionWrapper {
  .halfSection {
    width: 100%;
    max-width: 100%;

    $container-max-widths: (
      sm: 540px,
      md: 720px,
      lg: 960px,
      xl: 1170px,
      xxl: 1170px,
    ) !default;

    $grid-breakpoints: (
      sm: 576px,
      md: 768px,
      lg: 992px,
      xl: 1200px,
      xxl: 1400px,
    ) !default;

    @each $breakpoint, $min-width in $grid-breakpoints {
      @media screen and (min-width: $min-width) {
        @if $min-width == 576px {
          max-width: 100%;
        } @else {
          $container-width: map-get($container-max-widths, $breakpoint);
          max-width: $container-width/2;
        }
      }
    }
  }
}
