@use '../../../scss/variables' as vars;

.textwrapper{
    p{
        @media screen and (max-width: 576px) {
            height: vh;
          }
          @media screen and (min-width: 576px) {
            height: 4vh;
          }
          @media screen and (min-width: 768px) {
            height: 10vh;
          }
          @media screen and (min-width: 992px) {
            height: 15vh;
          }
          @media screen and (min-width: 1200px) {
            height: 18.3vh;
          }
          @media screen and (min-width: 1400px) {
            height: 18.3vh;
          }
    }
}