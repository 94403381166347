@use '../../../scss/variables' as vars;
@use '../../../scss/mixins' as mixins;

.uploadContainer {
  border: 0.2rem dashed vars.$color-desall-blue;
  border-radius: vars.$radius-s;
  min-height: 16rem;
  background-color: vars.$color-light;
  text-align: center;

  @include mixins.transitionall(0.2s);

  &.inProgress {
    border: 0.2rem solid vars.$color-light;
  }

  .dropArea {
    min-height: 16rem;

    & > div {
      padding: 3rem 6rem;
    }
  }

  .progressArea {
    min-height: 16rem;
    padding: 3rem 6rem;
  }
}

.progressBarWrapper {
  height: 2.5rem;
  width: 100%;
  border-radius: vars.$radius-s;
  background-color: vars.$color-light-gray-2;
  overflow: hidden;

  .progressBar {
    @include mixins.transitionall(0.5s);
    transition-delay: 0.5s;
    height: 2.5rem;
    background-color: vars.$color-desall-blue;
  }
}

.cancelButton {
  border: 0;
  background-color: transparent;
}

.uploadPreviewImg {
  background-color: vars.$color-light-gray-4;
  width: 100%;
  height: 10rem;
  border-radius: 0.5rem;
  display: block;
  object-fit: cover;
}
