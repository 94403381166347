@use '../../../scss/variables' as vars;

.card {
  // padding: 15px;

  .cardInfoWrapper {
    border-radius: vars.$radius-s;
    overflow: hidden;
    align-self: stretch;
    min-height: 100%;
    width: 270px;
    height: 540px;

    .cardInfoImage {
      width: 100%;
      height: 270px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .cardImagePlaceholder {
      height: 270px;
      background-color: vars.$color-light-gray-4;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 8rem;
    }

    .cardInfoContent {
      padding: 1rem;
      height: 270px;
      .infoContainer {
        padding-left: 15%;
      }
    }

    .squaredImageWrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0;
      overflow: hidden;
      width: 100%;
      height: 270px;
      // border: 1px solid red;

      .imageInner {
        min-width: 100%;
        height: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .imageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 1px solid red;
    height: 270px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: vars.$color-light-gray-2;
    position: relative;
  }

  .addButton {
    background-color: vars.$color-desall-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 70px;
    width: 70px;
  }
}
