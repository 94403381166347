@use '../../../scss/variables' as vars;
@use '../../../scss/mixins' as mixins;

// Custom dropdown
.dropdown_wrapper {
  position: relative;
  user-select: none;
  // border: 1px solid red;
  // width: 270px;
  // width: 100%;

  height: 3rem;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    width: 100%;
    // width: 270px;
    // max-width: 236px;
  }
  @media screen and (min-width: 1200px) {
    // width: 100%;
    width: 270px;
    // max-width: 236px;
  }

  .dropdown {
    position: relative;
    display: flex;
    flex-direction: column;

    &.open {
      .dropdown__toggle {
        border-radius: vars.$radius-m vars.$radius-m 0 0;
      }

      .arrow {
        top: 0.5rem;
        &::after {
          transform: rotate(-135deg);
        }
      }
    }
  }

  .dropdown__toggle {
    @include mixins.transitionall(0.2s);
    position: relative;

    background-color: vars.$color-light;
    border-radius: vars.$radius-m;
    height: 3rem;
    line-height: 1.2rem;
    padding: 0 1rem;
    font-size: vars.$font-size-xs;
    color: vars.$color-dark;

    display: flex;
    align-items: center;

    & .dropdown__label {
      padding: 0.4rem 0;
      overflow-y: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      padding-right: 2rem;
      color: vars.$color-desall-blue;
    }
  }
  // .dropdown__toggle:hover{
  //   cursor: pointer;
  //   box-shadow: 0px 0px 5px 0px rgba(100,100,100, 0.5);
  // }

  .arrow {
    @include mixins.transitionall(0.2s);
    display: inline-block;
    padding: 3px;
    // border: 1px solid red;

    position: absolute;
    right: 0.15rem;
    top: 0.1rem;

    width: 2.2rem;
    height: 2.2rem;
    // background-image: url("data:image/svg+xml;utf8,<svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='m17.488 8.53-5.979 5.979a.894.894 0 0 1-1.268 0L4.263 8.531A.896.896 0 1 1 5.53 7.263l5.344 5.344 5.344-5.344a.897.897 0 1 1 1.269 1.268z' fill='%23193A56' fill-rule='evenodd'/></svg>");
    // background-repeat: no-repeat;

    &::after {
      @include mixins.transitionall(0.2s);
      position: absolute;
      display: block;
      content: '';
      width: 1.2rem;
      height: 1.2rem;
      border: 0.23rem solid vars.$color-desall-blue;
      background: vars.$color-light;
      border-top: 0;
      border-left: 0;
      right: 1rem;
      top: 0.75rem;
      transform: rotate(45deg);
      cursor: pointer;
    }
  }

  .dropdown__optionslist {
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;
    background: rgba(vars.$color-light, 0.9);
    z-index: 10;
    // background-color: vars.$color-light-gray;

    @include mixins.transitionall(0.2s);

    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 1000;
    height: auto;
    min-height: 3rem;
    max-height: 30rem;
    overflow-y: scroll;
    // padding-bottom: 0.3rem;
    -moz-box-shadow: 0 4px 8px -4px rgba(26, 26, 26, 0.2);
    -webkit-box-shadow: 0 4px 8px -4px rgba(26, 26, 26, 0.2);
    box-shadow: 0 4px 8px -4px rgba(26, 26, 26, 0.2);

    @include mixins.customScrollbar(0.4rem, vars.$color-desall-blue, transparent);
  }

  .dropdown.open .dropdown__optionslist {
    opacity: 1;

    visibility: visible;
    pointer-events: all;
    border-radius: 0 0 vars.$radius-m vars.$radius-m;
    overflow: scroll;
    min-height: 3rem;
    max-height: 30rem;
  }

  .dropdown__option {
    position: relative;
    display: block;
    @include mixins.transitionall(0.2s);
    padding: 5px;
    background: rgba(vars.$color-light, 0.9);
    height: 3.9rem;
    line-height: 3.9rem;

    padding: 0 1rem;
    font-size: vars.$font-size-xs;
    color: vars.$color-dark;

    overflow: hidden;

    //border-top: 1px solid #f1f1f1;
  }

  .dropdown__option:last-child {
    border-radius: 0 0 8px 8px;
  }
  .dropdown__option:hover {
    cursor: pointer;
    background-color: rgb(231, 233, 237);
  }
  .dropdown__option.selected {
    background-color: vars.$color-light-gray;
    color: vars.$color-desall-blue;
    font-weight: 700;
  }

  .dropdown__option_sort {
    position: relative;
    display: block;
    @include mixins.transitionall(0.2s);
    padding: 5px;
    background: rgba(vars.$color-light, 0.9);
    height: 3.9rem;
    line-height: 3.9rem;

    padding: 0 1rem;
    font-size: vars.$font-size-xs;
    color: vars.$color-dark;

    overflow: hidden;
    // border: 1px solid red;
  }

  .dropdown__option_sort.selected {
    background-color: vars.$color-light-gray;
    color: vars.$color-desall-blue;
    font-weight: 700;
  }

  .dropdown__option_sort:last-child {
    border-radius: 0 0 8px 8px;
  }
  .dropdown__option_sort:hover {
    cursor: pointer;
    background-color: rgb(231, 233, 237);
  }

  .dropdown__option_noText {
    position: relative;
    display: block;
    @include mixins.transitionall(0.2s);
    padding: 5px;
    background: rgba(vars.$color-light, 0.9);
    height: 3.9rem;
    line-height: 3.9rem;
    pointer-events: none;
    padding: 0 1rem;
    font-size: vars.$font-size-xs;
    color: vars.$color-dark;
    cursor: cell !important;
    //border-top: 1px solid #f1f1f1;
  }

  &.thin_ui {
    .dropdown__toggle {
      height: 3.1rem;
      border-radius: vars.$radius-s;
    }
    .arrow {
      width: 0.8rem;
      height: 0.8rem;

      &::after {
        top: 0.9rem;
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

// Custom dropdown YEAR
.dropdown_wrapper_year {
  position: relative;
  user-select: none;
  width: 100%;
  cursor: pointer;

  .dropdown {
    position: relative;
    display: flex;
    flex-direction: column;

    &.open {
      .dropdown__toggle {
        border-radius: vars.$radius-m vars.$radius-m 0 0;
      }

      .arrow {
        top: 0.5rem;
        &::after {
          transform: rotate(-135deg);
        }
      }
    }
  }

  .dropdown__toggle {
    @include mixins.transitionall(0.2s);
    position: relative;

    background-color: vars.$color-light;
    border-radius: vars.$radius-m;
    height: 3rem;
    line-height: 1.2rem;
    padding: 0 1rem;
    font-size: vars.$font-size-xs;
    color: vars.$color-dark;

    display: flex;
    align-items: center;

    & .dropdown__label {
      padding: 0.4rem 0;
      overflow-y: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 2rem;
    }
  }
  // .dropdown__toggle:hover{
  //   cursor: pointer;
  //   box-shadow: 0px 0px 5px 0px rgba(100,100,100, 0.5);
  // }

  .arrow {
    @include mixins.transitionall(0.2s);
    display: inline-block;
    padding: 3px;
    // border: 1px solid red;

    position: absolute;
    right: 0.15rem;
    top: 0.1rem;

    width: 2.2rem;
    height: 2.2rem;
    // background-image: url("data:image/svg+xml;utf8,<svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='m17.488 8.53-5.979 5.979a.894.894 0 0 1-1.268 0L4.263 8.531A.896.896 0 1 1 5.53 7.263l5.344 5.344 5.344-5.344a.897.897 0 1 1 1.269 1.268z' fill='%23193A56' fill-rule='evenodd'/></svg>");
    // background-repeat: no-repeat;

    &::after {
      @include mixins.transitionall(0.2s);
      position: absolute;
      display: block;
      content: '';
      width: 1rem;
      height: 1rem;
      border: 0.23rem solid vars.$color-desall-blue;
      background: vars.$color-light;
      border-top: 0;
      border-left: 0;
      right: 1rem;
      top: 1rem;
      transform: rotate(45deg);
      cursor: pointer;
    }
  }

  .dropdown__optionslist {
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;
    background: rgba(vars.$color-light, 0.9);
    // background-color: vars.$color-light-gray;

    @include mixins.transitionall(0.2s);

    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 1000;
    height: auto;
    max-height: 30rem;
    overflow-y: scroll;
    // padding-bottom: 0.3rem;

    box-shadow: 0 4px 8px -4px rgba(26, 26, 26, 0.2);

    @include mixins.customScrollbar(0.4rem, vars.$color-desall-blue, transparent);
  }

  .dropdown.open .dropdown__optionslist {
    opacity: 1;

    visibility: visible;
    pointer-events: all;
    border-radius: 0 0 vars.$radius-m vars.$radius-m;
  }

  .dropdown__option {
    position: relative;
    display: block;
    @include mixins.transitionall(0.2s);
    padding: 5px;
    background: rgba(vars.$color-light, 0.9);
    height: 3.9rem;
    line-height: 3.9rem;

    padding: 0 1rem;
    font-size: vars.$font-size-xs;
    color: vars.$color-dark;

    //border-top: 1px solid #f1f1f1;
  }

  .dropdown__option:last-child {
    border-radius: 0 0 8px 8px;
  }
  .dropdown__option:hover {
    cursor: pointer;
    background-color: rgb(231, 233, 237);
  }
  .dropdown__option.selected {
    background-color: vars.$color-light-gray;
    color: vars.$color-desall-blue;
  }

  &.thin_ui {
    .dropdown__toggle {
      height: 3.1rem;
      border-radius: vars.$radius-s;
    }
    .arrow {
      width: 0.8rem;
      height: 0.8rem;

      &::after {
        top: 0.9rem;
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
.transitionIn {
  animation-duration: 0.15s;
  animation-name: animationIn;
}

@keyframes animationIn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.freccia {
  height: 0.8rem;
}

.frecciaRuotata {
  height: 0.8rem;
  transform: rotate(180deg);
}
