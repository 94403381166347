@use '../../scss/variables' as vars;
@use '../../scss/mixins' as mixins;

.sideContainer{
  @media screen and (min-width: 576px) {

  }
  @media screen and (min-width: 768px) {
   
  }
  @media screen and (min-width: 992px) {
    min-width: 240px;
    max-width: 250px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 295px;
  }
  
}

.removeRevision {
  height: 30px;
  width: 30px;
  background-color: vars.$color-danger;
}

.removeRevision:hover {
  opacity: 0.7;
}
.arrow {
  display: flex;
  margin-right: 3rem;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;

  .angle {
    // border: 1px solid black;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.transitionIn {
  animation-duration: 0.2s;
  animation-name: animationIn;
}

@keyframes animationIn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.toggle {
  @include mixins.transitionall(0.2s);
  position: relative;

  background-color: vars.$color-desall-blue;
  border-radius: vars.$radius-m;
  height: 30px;
  line-height: 1.2rem;
  padding: 0 1rem;
  font-size: vars.$font-size-xs;
  color: vars.$color-dark;

  display: flex;
  align-items: center;

  & .dropdown__label {
    padding: 0.4rem 0;
    overflow-y: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 2rem;
  }
}

.arrow {
  position: relative;
  @include mixins.transitionall(0.2s);
  display: inline-block;
  padding: 3px;
  // border: 1px solid red;

  position: absolute;
  right: 0.15rem;
  top: -0.2rem;

  width: 2.2rem;
  height: 2.2rem;
  // background-image: url("data:image/svg+xml;utf8,<svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='m17.488 8.53-5.979 5.979a.894.894 0 0 1-1.268 0L4.263 8.531A.896.896 0 1 1 5.53 7.263l5.344 5.344 5.344-5.344a.897.897 0 1 1 1.269 1.268z' fill='%23193A56' fill-rule='evenodd'/></svg>");
  // background-repeat: no-repeat;

  &::after {
    @include mixins.transitionall(0.2s);
    position: absolute;
    display: block;
    content: '';
    width: 1.2rem;
    height: 1.2rem;
    border: 0.23rem solid vars.$color-light;
    background: vars.$color-desall-blue;
    border-top: 0;
    border-left: 0;
    right: 1rem;
    top: 0.75rem;
    transform: rotate(45deg);
    cursor: pointer;
  }
}
.open {
  .arrow {
    top: 0.3rem;
    &::after {
      transform: rotate(-135deg);
    }
  }
}


