@use '../../scss/variables' as vars;

.squaredImageWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0;
  overflow: hidden;
    

  &.circle {
    border-radius: 50%;
  }

  .imageInner {
    aspect-ratio: 1 / 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  

    & img {
  
      // aspect-ratio: 1 / 1;
      object-fit: fill;
      // width: 100%;
      height: 100%;
    }
    & button{
      width: 30px;
      height: 30px;
      position: static;
      
    }
  }
}
