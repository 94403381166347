@use '../../scss/variables' as vars;

.designWrapper {
  .profilePic {
    width: 100%;
    position: relative;
  }

  .badges {
    position: relative;
    margin-top: -5rem;

    .badge {
      width: 6rem;
      height: 6rem;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3rem;
      margin-left: -2.5rem;
    }
  }
}
