@use '../../../scss/variables' as vars;

.nouser {
  // border: 2px dashed vars.$color-dark_grey_50;
}

.noImage {
  height: 140px;
  width: 140px;
  border-radius: 8px;
  background-color: vars.$color-light-gray;
}

.noProfileImg {
  height: 140px;
  width: 140px;
  border-radius: 50%;
  background-color: vars.$color-light-gray;
}

.cursorPointer{
  cursor: pointer;
}