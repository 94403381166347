@use '../../../scss/variables' as vars;

.profileHeaderWrapper {
  border-radius: vars.$radius-m;
  overflow: hidden;
  max-height: 25rem;
  // border: 1px solid red;

  .addButton {
    border-width: 0;
    border-radius: 50%;
    width: 6rem;
    height: 6rem;

    svg {
      font-size: 4rem;
      line-height: 6rem;
    }

    &:hover {
      cursor: pointer;
    }
  }
  
  .profileHeaderImageContainer {
    display: flex;
    
    height: 233px;
    width: 100%;

    max-height: 25rem;
    background-color: vars.$color-light_grey_15;

    img {
      
      max-width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .removeButton {
    position: absolute;
    top: 2rem;
    right: 2rem;
    border-width: 0;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 2rem;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
