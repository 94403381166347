@use '../../../scss/variables' as vars;

.designerRowWrapper {
  border-radius: vars.$radius-s;
  height: auto;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    border-radius: 8px;
    width: 560px;
    // border: 1px solid red;
  }
  @media screen and (max-width: 992px) {
    border-radius: 8px;
    max-width: 690px;
  }
  @media screen and (min-width: 992px) {
    height: 9rem;
  }

  .designerRowImage {
    width: 9rem;
    height: 9rem;
  }

  .designerRowImagePlaceholder {
    border-radius: 50%;
    height: 9rem;
    width: 9rem;
    background-color: vars.$color-light-gray-4;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }

  .designerRowGallery {
    overflow: hidden;
    border-radius: vars.$radius-s;
    height: 9rem;

    & > div:first-child {
      overflow: hidden;
      border-radius: 0 0 0 vars.$radius-s;

      @media screen and (min-width: 992px) {
        border-radius: vars.$radius-s 0 0 vars.$radius-s;
      }
    }

    & > div:last-child {
      overflow: hidden;
      border-radius: 0 0 vars.$radius-s 0;

      @media screen and (min-width: 992px) {
        border-radius: 0 vars.$radius-s vars.$radius-s 0;
      }
    }

    img {
      width: 100%;
      height: 9rem;
      object-fit: cover;

      @media screen and (min-width: 992px) {
        width: 9rem;
      }
    }

    .designerRowGalleryItem {
      height: 9rem;
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;

      @media screen and (min-width: 992px) {
        width: 9rem;
      }
    }
  }
}
