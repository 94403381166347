@use './variables' as vars;

label,
.label {
  font-weight: bold;
  padding-right: 0.5rem;
  color: vars.$color-dark;

  &.inline {
    font-weight: normal;
    font-family: vars.$font-body;
    font-size: vars.$font-size-xs;
    display: inline;
    color: vars.$color-dark;
  }

  &.label-tooltip {
    border: 1px solid red;
  }
}

.form-tooltip {
  text-align: left !important;
  color: vars.$color-dark !important;
}

input {
  height: 3rem;

  &.inline {
    width: 100%;

    @media screen and (min-width: 576px) {
      width: auto;
    }
  }
}

input,
textarea {
  background-color: vars.$color-light;
  border: 1px solid vars.$color-light;
  border-radius: vars.$radius-s;
  width: 100%;
  &:focus {
    outline: none;
    border: 1px solid vars.$color-light-gray-5;
  }
  &:disabled {
    background-color: vars.$color-light-gray-2;
    border: 1px solid vars.$color-light-gray-5;
    color: vars.$color-light-gray-5;
    cursor: not-allowed;
  }

  &::placeholder {
    color: vars.$color-light-gray-5;
  }
}

textarea {
  position: relative;
  &::placeholder {
    // text-align: center;
    position: relative;
    // top: 48%;
    width: 99%;
  }
}

input[type='text'],
input[type='password'],
input[type='email'] {
  padding: 0.3rem 0.5rem;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'],
  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      outline: none;
      border: 1px solid vars.$color-light;
    }

    height: 3rem;
    width: 3rem;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;

    background: vars.$color-light;
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      opacity: 0;
      position: absolute;
      border: 1px solid vars.$color-desall-blue;
      background: vars.$color-desall-blue;
      transition: transform 0.3s ease, opacity 0.2s;
    }

    &:checked {
      transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.32, 1.2);

      &:after {
        opacity: 1;
        transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.32, 1.2), opacity 0.3s;
      }
    }
  }

  input[type='radio'] {
    border-radius: 50%;

    &:after {
      top: -0.1rem;
      left: -0.1rem;
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
      background: vars.$color-desall-blue;
    }
    &:checked {
      &:after {
        background: vars.$color-desall-blue;
        transform: scale(0.5);
      }
    }
  }

  input[type='checkbox'] {
    border-radius: vars.$radius-s;
    &:after {
      width: 0.8rem;
      height: 1.3rem;
      border: 0.3rem solid vars.$color-desall-blue;
      background: vars.$color-light;
      border-top: 0;
      border-left: 0;
      left: 1rem;
      top: 0.6rem;
      transform: scale(0.5) rotate(20deg);
    }

    &:checked {
      &:after {
        transform: scale(1) rotate(43deg);
      }
    }
  }
}

input[type='checkbox'] + label,
input[type='radio'] + label {
  display: inline;
  vertical-align: middle;
  padding-left: 1.5rem;
  line-height: 3rem;
  cursor: pointer;
}

// Base dropdow
.dropdown-row {
  background-color: vars.$color-light;
  border-radius: vars.$radius-s;
  padding: 1rem 1rem;
  color: vars.$color-dark;
  position: relative;

  .custom-dropdown {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1rem 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    outline: none;
    cursor: pointer;

    color: vars.$color-dark;

    option {
      border: 0;
      width: 100%;
      padding: 1rem 1rem;
      font-size: 14px;
    }
  }

  &::after {
    position: absolute;
    display: block;
    content: '';
    width: 1.2rem;
    height: 1.2rem;
    border: 0.4rem solid vars.$color-desall-blue;
    background: vars.$color-light;
    border-top: 0;
    border-left: 0;
    right: 1rem;
    top: 1.2rem;
    transform: rotate(45deg);
    cursor: pointer;
  }
}
