@use '../../scss/variables' as vars;
@import '../../scss/mixins';

.cardWrapper {
  width: 100%;
  height: 37rem;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  @media screen and (min-width: 576px) {
  }
  @media screen and (min-width: 768px) {
    width: 30rem;
    height: 30rem;
  }
  @media screen and (min-width: 992px) {
  }
  @media screen and (min-width: 1200px) {
    width: 36rem;
    height: 37rem;
  }
  @media screen and (min-width: 1400px) {
  }

  img {
    border-radius: 8px !important;
    display: block;
    object-fit: fill;
  }

  .cardOverlay {
    @include transitionall(0.2s);
    width: 100%;
    height: 37rem;
    background: rgba(vars.$color-desall-blue, 0.8);
    // border: 1px solid blue; 
    // border: 1px solid red;
    // padding: 1rem;
    position: absolute;
    border-radius: 8px !important;
    // border: 1px solid rgba(vars.$color-desall-blue, 0.8);
    top: 0;
    left: 0;
    opacity: 0;

    @media screen and (min-width: 576px) {
    }
    @media screen and (min-width: 768px) {
      width: 30rem;
      height: 30rem;
    }
    @media screen and (min-width: 992px) {
      width: 29.5rem;
      height: 30rem;
    }
    @media screen and (min-width: 1200px) {
      width: 36rem;
      height: 37rem;
    }
    @media screen and (min-width: 1400px) {
    }
  }

  &:hover {
    img {
      overflow: hidden;
      filter: blur(0.3rem);
      border-radius: 8px !important;
    }
    cursor: pointer;
    .cardOverlay {
      opacity: 1;
      width: 100%;
    }
  }

  &.visibleContent {
    width: 100%;
    border-radius: 8px !important;
    .cardOverlay {
      border-radius: 8px !important;
      opacity: 1;
    }

    &:hover {
      img {
        border-radius: 8px !important;
        overflow: hidden;
        filter: none;
      }
      cursor: pointer;
      .cardOverlay {
        border-radius: 8px !important;
        opacity: inherit;
      }
    }
  }
}

.cardWrapperNoHover {
  width: 100%;
  height: 37rem;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  @media screen and (min-width: 576px) {
  }
  @media screen and (min-width: 768px) {
    width: 30rem;
    height: 30rem;
  }
  @media screen and (min-width: 992px) {
  }
  @media screen and (min-width: 1200px) {
    width: 36rem;
    height: 37rem;
  }
  @media screen and (min-width: 1400px) {
  }

  img {
    // border-radius: 8px;
    display: block;
    object-fit: fill;
    @media screen and (min-width: 576px) {
    }
    @media screen and (min-width: 768px) {
      // width: 30rem;
      // height: 30rem;
    }
    @media screen and (min-width: 992px) {
    }
    @media screen and (min-width: 1200px) {
      // width: 36rem;
      // height: 37rem;
    }
    @media screen and (min-width: 1400px) {
    }
  }

  .cardOverlay {
    @include transitionall(0.2s);
    width: 100%;
    height: 37rem;
    background: rgba(vars.$color-desall-blue, 0.8);
    padding: 1rem;
    position: absolute;
    border-radius: 8px;
    top: 0;
    left: 0;
    opacity: 0;

    @media screen and (min-width: 576px) {
    }
    @media screen and (min-width: 768px) {
      width: 30rem;
      height: 30rem;
    }
    @media screen and (min-width: 992px) {
      width: 29.5rem;
      height: 30rem;
    }
    @media screen and (min-width: 1200px) {
      width: 36rem;
      height: 37rem;
    }
    @media screen and (min-width: 1400px) {
    }
  }
}
