@use '../../../../scss/variables' as vars;
@use '../../../../scss/mixins' as mixins;

.uploadContainer {
  height: 100%;
  border: 0.2rem dashed vars.$color-desall-blue;
  border-radius: vars.$radius-s;
  min-height: 22rem;
  background-color: transparent;
  text-align: center;
  

  @include mixins.transitionall(0.2s);

  &.inProgress {
    border: 0.2rem dashed vars.$color-desall-blue;
  }

  .dropArea {
    height: 100%;
    min-height: 22rem;
    
    

    & > div {
      padding: 3rem 6rem;
    }

    .addButton {
      border-width: 0;
      border-radius: 50%;
      width: 6rem;
      height: 6rem;

      svg {
        font-size: 4rem;
        line-height: 6rem;
        @media screen and (min-width: 768px) {
          font-size: 3rem;
        }
        @media screen and (min-width: 992px) {
          font-size: 4rem;
        }
        @media screen and (min-width: 1200px) {
          font-size: 4rem;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .progressArea {
    height: 100%;
    max-height: 17rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    padding: 3rem 6rem;
  }
}

.progressBarWrapper {
  
  height: 2.5rem;
  width: 100%;
  border-radius: vars.$radius-s;
  background-color: vars.$color-light-gray-2;
  overflow: hidden;

  .progressBar {
    @include mixins.transitionall(0.5s);
    transition-delay: 0.5s;
    height: 2.5rem;
    background-color: vars.$color-desall-blue;
  }
}

.cancelButton {
  border: 0;
  background-color: transparent;
}

.uploadPreviewImg {
  background-color: vars.$color-light-gray-4;
  width: 100%;
  height: 10rem;
  border-radius: 0.5rem;
  display: block;
  object-fit: cover;
}
