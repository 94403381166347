@use '../../scss/variables' as vars;
@import '../../scss/mixins';

.cardWrapper {
  width: 100%;
  height: 27rem;
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;

  @media screen and (min-width: 576px) {
  }
  @media screen and (min-width: 768px) {
    width: 27rem;
  }
  @media screen and (min-width: 992px) {
    width: 23rem;
    height: 23rem;
  }
  @media screen and (min-width: 1200px) {
    width: 26rem;
    height: 26rem;
  }
  @media screen and (min-width: 1400px) {
  }

  img {
    border-radius: 0.5rem;
    display: block;
    object-fit: cover;
  }

  .cardOverlay {
    @include transitionall(0.2s);
    width: 100%;
    height: 27rem;
    background: rgba(vars.$color-desall-blue, 0.8);
    padding: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    @media screen and (min-width: 576px) {
    }
    @media screen and (min-width: 768px) {
      width: 27rem;
    }
    @media screen and (min-width: 992px) {
      width: 23rem;
      height: 23rem;
    }
    @media screen and (min-width: 1200px) {
      width: 27rem;
      height: 27rem;
    }
    @media screen and (min-width: 1400px) {
    }
  }

  &:hover {
    img {
      overflow: hidden;
      filter: blur(0.3rem);
    }
    cursor: pointer;
    .cardOverlay {
      opacity: 1;
    }
  }

  &.visibleContent {
    .cardOverlay {
      opacity: 1;
    }

    &:hover {
      img {
        overflow: hidden;
        filter: none;
      }
      cursor: pointer;
      .cardOverlay {
        opacity: inherit;
      }
    }
  }
}

.cardWrapperNoHover {
  width: 100%;
  height: 27rem;
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;

  @media screen and (min-width: 576px) {
  }
  @media screen and (min-width: 768px) {
    width: 27rem;
  }
  @media screen and (min-width: 992px) {
    width: 23rem;
    height: 23rem;
  }
  @media screen and (min-width: 1200px) {
    width: 26rem;
    height: 26rem;
  }
  @media screen and (min-width: 1400px) {
  }

  img {
    border-radius: 0.5rem;
    display: block;
    object-fit: cover;
  }

  .cardOverlay {
    @include transitionall(0.2s);
    width: 100%;
    height: 27rem;
    background: rgba(vars.$color-desall-blue, 0.8);
    padding: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    @media screen and (min-width: 576px) {
    }
    @media screen and (min-width: 768px) {
      width: 27rem;
    }
    @media screen and (min-width: 992px) {
      width: 23rem;
      height: 23rem;
    }
    @media screen and (min-width: 1200px) {
      width: 27rem;
      height: 27rem;
    }
    @media screen and (min-width: 1400px) {
    }
  }
}
