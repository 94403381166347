@use '../../../scss/variables' as vars;

.cardInfoWrapper {
  border-radius: vars.$radius-s;
  overflow: hidden;
  align-self: stretch;
  border-radius: 8px;
  // border: 1px solid red;

  // min-height: 100%;
  @media screen and (min-width: 576px) {
    width: 100%;
    
  }
  @media screen and (min-width: 768px) {
    max-width: 320px;
    // height: 540px;
  }
  @media screen and (min-width: 1200px) {
    width: 270px;
    height: 540px;
    max-width: 270px;
  }
  .cardInfoImage {
    img {
      object-fit: cover;
      width: 100%;
      // height: 25rem;
    }
  }

  .cardImagePlaceholder {
    //height: 27.5rem;
    background-color: vars.$color-light-gray-4;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8rem;
  }

  .cardInfoContent {
    padding: 1rem;
  }

  .squaredImageWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;
    overflow: hidden;
    width: 100%;

    .imageInner {
      min-width: 100%;
      aspect-ratio: 1 / 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      // border: 1px solid red;
      background-color: vars.$color-light_grey_15;
      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: fill;
      }
    }
  }
}
