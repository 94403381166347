@use './variables' as vars;

@mixin ratio-1-1 {
  aspect-ratio: 1 / 1;
}

@mixin img-desall {
  border-radius: vars.$radius-s;
  display: block;
  object-fit: cover;

  &.fit-contain {
    object-fit: contain;
  }

  &.fit-cover {
    object-fit: cover;
  }

  &.fit-fill {
    object-fit: fill;
  }

  &.fit-none {
    object-fit: none;
  }

  &.squared {
    border-radius: 0 !important;
  }

  &.rounded {
    border-radius: vars.$radius-m !important;
  }

  &.circle {
    border-radius: 50% !important;
    width:80%;
    @media screen and (min-width: 576px) {
      border-radius: 50% !important;
      width:80% !important;
      height: 110%;
    }
    @media screen and (min-width: 768px) {
      border-radius: 50% !important;
    }
    
  }
}

.img-fluid {
  @include img-desall;
  // @include ratio-1-1;
}

.img-270 {
  @include img-desall;
  width: 100%;
  height: 27rem;

  @media screen and (min-width: 576px) {
    width:27rem;
  }
  @media screen and (min-width: 768px) {
    width: 27rem;
  }
  @media screen and (min-width: 992px) {
    width: 23rem;
    height: 23rem;
  }
  @media screen and (min-width: 1200px) {
    width: 27rem;
    height: 27rem;
  }
  @media screen and (min-width: 1400px) {
  }
}

.img-370 {
  @include img-desall;
  width: 100%;
  height: 37rem;

  @media screen and (min-width: 576px) {
  }
  @media screen and (min-width: 768px) {
    width: 21.5rem;
    height: 30rem;
    
  }
  @media screen and (min-width: 992px) {
    width: 29.5rem;
    height: 30rem;
  }
  @media screen and (min-width: 1200px) {
    width: 36rem;
    height: 37rem;
  }
  @media screen and (min-width: 1400px) {
  }
}

.img-550 {
  @include img-desall;
  width: 100%;
  height: 55.5rem;

  @media screen and (min-width: 576px) {
  }
  @media screen and (min-width: 768px) {
    width: 32.5rem;
    height: 33.5rem;
  }
  @media screen and (min-width: 992px) {
    width: 44.5rem;
    height: 45.5rem;
  }
  @media screen and (min-width: 1200px) {
    width: 54.5rem;
    height: 55.5rem;
  }
  @media screen and (min-width: 1400px) {
  }
}

.img-1170-wide {
  @include img-desall;
  width: 117rem;
  height: 33rem;
}
