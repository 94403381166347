@use '../../../scss/variables' as vars;

.card {
  padding: 15px;
}

.cardInfoWrapper {
  border-radius: vars.$radius-m;
  overflow: hidden;
  align-self: stretch;
  // min-height: 100%;

  @media screen and (min-width: 576px) {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    max-width: 320px;
  }
  @media screen and (min-width: 992px) {
    min-width: 270px;
    height: 540px;

    max-width: 270px;
  }
  
  .cardInfoImage {
    // width: 270px;
    // height: 270px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .cardImagePlaceholder {
    // height: 27.5rem;
    background-color: vars.$color-light-gray-4;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8rem;
    width: 100%;
    height: 270px;
  }

  .cardInfoContent {
    padding: 1rem;
    height: 270px;
    .infoContainer {
      padding-left: 15%;
    }
  }

  .squaredImageWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;
    overflow: hidden;
    width: 100%;
    

    .imageInner {
      min-width: 100%;
      aspect-ratio: 1 / 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
