@use '../../scss/variables' as vars;
@use '../../scss/mixins' as mixins;

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: rgba(vars.$color-desall-blue, 0.6);

  display: flex;
  align-items: center;
  justify-content: center;

  @include mixins.transitionall(0.3s);

  .modal__content_wrapper {
    position: relative;
    background-color: vars.$color-light;
    color: vars.$color-dark;
    border-radius: vars.$radius-m;
    width: 70%;
    max-width: 60rem;

    padding: 2.5rem 2rem;

    @include mixins.transitionall(0.3s);
  }

  &.visible {
    opacity: 1;
    display: flex;
    z-index: 10000;

    .modal__content_wrapper {
      margin-top: 0rem;
    }
  }
  &:not(.visible) {
    opacity: 0;
    z-index: -10000;

    .modal__content_wrapper {
      margin-top: -20rem;
    }
  }

  .confirmBlue {
    background: vars.$color-desall-blue !important;
  }
  .confirmGreen {
    background: vars.$color-success !important;
  }
  .confirmYellow {
    background: vars.$color-desall-yellow !important;
  }
  .confirmRed {
    background: vars.$color-desall-red !important;
  }
  .confirmDefault {
    background: vars.$color-dark-blue !important;
  }
}
