@use '../../scss/variables' as vars;
@use '../../scss/mixins' as mixins;

.profileWrapper {
  .profilePic {
    & > div {
      // max-height: 270px;
      // max-width: 270px;
      // transform: translateY(-32%);
      // @media screen and (max-width: 576px) {
      //   max-height: 270px;

      //   margin-bottom: 7rem;
      // }
      // @media screen and (min-width: 768px) {
      //   margin-top: -3rem;
      //   width: '270px';
      //   height: '270px';
      //   max-width: 270px;
      // }
      @media screen and (min-width: 576px) {
        // margin-top: -0rem;
        // width: 270px;
        // height: 270px;
        max-width: 100%;
        margin-top: -7rem;
      }
      @media screen and (min-width: 768px) {
        margin-top: -10rem;
        width: 270px;
        height: 270px;
        max-width: 270px;
      }

      @media screen and (min-width: 992px) {
        margin-top: -5rem;
        width: 270px;
        height: 270px;
        max-width: 270px;

        // transform: translateY(-15%);
      }
      @media screen and (min-width: 1200px) {
        margin-top: -5rem;
        width: 270px;
        height: 270px;
        max-width: 270px;
        transform: translateX(-6px);
        // transform: translateY(-15%);
      }
    }

    .badge {
      width: 70px;
      height: 70px;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3rem;
      margin-left: -2.5rem;
    }
    .badgeOver {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      align-self: flex-start;
      justify-content: center;
      transform: translateY(-25%);
      font-size: 3rem;
      margin-left: -2.5rem;
    }
  }
}

.picUp {
  @media screen and (min-width: 768px) {
    transform: translateY(-30%);
    -ms-transform: translateY(-30%);
    -webkit-transform: translateY(-30%);
    -moz-transform: translateY(-30%);
    -o-transform: translateY(-30%);
  }
}
.bannerPic {
  height: 40px;
  @media screen and (max-width: 576px) {
    height: 65px;
  }
  @media screen and (max-width: 768px) {
    height: 60px;
  }
}

.badges {
  position: relative;
  margin-top: -5rem;

  .badge {
    width: 70px;
    height: 70px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    margin-left: -2.5rem;
  }
}

.badgePic {
  & > div {
    max-height: 250px;
    max-width: 250px;
    margin-bottom: 55px;
    @media screen and (max-width: 576px) {
      max-height: 250px;

      margin-bottom: 7rem;
    }
    @media screen and (min-width: 768px) {
      max-width: 250px;
    }
  }
}

textarea {
  height: 150px;
  resize: none;
  color: vars.$color-dark_grey_90;
  padding: 1rem 1rem !important;
}

input {
  height: 30px;
  color: vars.$color-dark_grey_90;
}

input[type='text'] {
  height: 30px;
  color: vars.$color-dark_grey_90;
  padding: 0px 1rem;
}
textarea::placeholder {
  position: relative;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
}

.country {
  min-height: 30px;
  width: 100%;
}

.birthDateInput {
  height: 30px !important;
  border-radius: 8px;
  border: none !important;
  color: vars.$color-dark_grey_90 !important;
  font-family: vars.$font-body !important;
  font-size: vars.$font-size-xs !important;
  &::placeholder {
    color: vars.$color-dark_grey_90 !important;
  }
}

.button {
  height: 30px !important;
}

.suggestionWrapper {
  // border: 1px solid red;
  height: 300px;
  position: absolute;
  width: 300px;
  border-radius: 8px;
  background-color: vars.$color-light;
  z-index: 3;
  overflow: scroll;
  .suggestionRow {
    height: 30px;
    // border: 1px solid blue;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 15px;
  }
}

// .arrow {
//   display: flex;
//   margin-right: 3rem;
//   align-items: center;
//   justify-content: center;
//   flex-direction: row;
//   cursor: pointer;
//   // border: 1px solid red;
//   .angle {
//     // border: 1px solid black;
//     width: 2.2rem;
//     height: 2.2rem;
//   }
// }

// .transitionIn {
//   animation-duration: 0.2s;
//   animation-name: animationIn;
// }

// @keyframes animationIn {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
.open {
  .arrow {
    top: 0.3rem;
    &::after {
      transform: rotate(-135deg);
    }
  }
}
.toggle {
  @include mixins.transitionall(0.2s);
  position: relative;

  background-color: vars.$color-desall-blue;
  border-radius: vars.$radius-m;
  height: 30px;
  line-height: 1.2rem;
  padding: 0 1rem;
  font-size: vars.$font-size-xs;
  color: vars.$color-dark;

  display: flex;
  align-items: center;

  & .dropdown__label {
    padding: 0.4rem 0;
    overflow-y: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 2rem;
  }
}
.arrow {
  position: relative;
  @include mixins.transitionall(0.2s);
  display: inline-block;
  padding: 3px;
  // border: 1px solid red;

  position: absolute;
  right: 0.15rem;
  top: -0.2rem;

  width: 2.2rem;
  height: 2.2rem;
  // background-image: url("data:image/svg+xml;utf8,<svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='m17.488 8.53-5.979 5.979a.894.894 0 0 1-1.268 0L4.263 8.531A.896.896 0 1 1 5.53 7.263l5.344 5.344 5.344-5.344a.897.897 0 1 1 1.269 1.268z' fill='%23193A56' fill-rule='evenodd'/></svg>");
  // background-repeat: no-repeat;

  &::after {
    @include mixins.transitionall(0.2s);
    position: absolute;
    display: block;
    content: '';
    width: 1.2rem;
    height: 1.2rem;
    border: 0.23rem solid vars.$color-light;
    background: vars.$color-desall-blue;
    border-top: 0;
    border-left: 0;
    right: 1rem;
    top: 0.75rem;
    transform: rotate(45deg);
    cursor: pointer;
  }
}

.removeButton {
  border-width: 0;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 2rem;
  }
  &:hover {
    cursor: pointer;
  }
}
