@use '../../scss/variables' as vars;
@use '../../scss/mixins' as mixins;

$timeline-height: 45px;

.timelineContainer {
  padding-top: $timeline-height;
  

  .timelineWrapper {
    border-radius: vars.$radius-m;
    height: $timeline-height;
    background-color: vars.$timeline-gray-3;

    & > div:last-child {
      border-radius: 0 vars.$radius-m vars.$radius-m 0;
    }

    .endSection {
      background-color: vars.$timeline-gray-3;
      
    }

    &.closed {
      .endSection {
        background-color: vars.$color-danger;
      }
    }
  }

  .progressWrapper {
    position: relative;

    .progressBar {
      height: $timeline-height;
    }

    .progressLabel {
      @include mixins.transitionall(0.2s);
      position: absolute;
      height: 8.1rem;
      top: -3.6rem;
      right: 0;
      width: 15rem;

      text-transform: uppercase;

      .progressSub {
        font-size: 1rem;
      }
    }

    .progressIcon {
      position: absolute;
      height: $timeline-height;
      top: 0;
      left: 0;
      text-transform: lowercase;
      z-index: 10;
      width: 100%;
    }

    &.upload {
      background-color: vars.$timeline-gray-1;

      .progressBar {
        background-color: vars.$color-success;
        @include mixins.transitionall(0.2s);
      }

      .progressLabel {
        color: vars.$color-success;
        border-right: 3px solid vars.$color-success;
      }

      &.closed {
        .progressBar {
          background-color: vars.$timeline-gray-1;
        }
      }
    }

    &.community {
      min-width: 3rem;
      background-color: vars.$timeline-gray-4;

      .progressBar {
        background-color: vars.$color-warning;
        @include mixins.transitionall(0.2s);
      }

      .progressLabel {
        color: vars.$color-warning;
        border-right: 3px solid vars.$color-warning;
      }

      &.closed {
        .progressBar {
          background-color: vars.$timeline-gray-4;
        }
      }
    }

    &.client {
      min-width: 3rem;
      background-color: vars.$timeline-gray-2;

      .progressBar {
        background-color: vars.$color-warning;
        @include mixins.transitionall(0.2s);
      }

      .progressLabel {
        color: vars.$color-warning;
        border-right: 3px solid vars.$color-warning;
      }

      &.closed {
        .progressBar {
          background-color: vars.$timeline-gray-2;
        }
      }
    }

    &.endSection {
      // border: 1px solid red;
      max-width: 9px;
      .progressBar {
      
        border-radius: 0 vars.$radius-m vars.$radius-m 0;
        background-color: vars.$color-danger;
        @include mixins.transitionall(0.2s);
      }

      .progressLabel {
        color: vars.$color-danger;
        border-right: 3px solid vars.$color-danger;
      }
    }
  }
}
