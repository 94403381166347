@use '../../scss/variables' as vars;
@use '../../scss/mixins' as mixins;

input[type='file']::file-selector-button {
  margin-right: 20px;
  border: none;

  color: #7997c5;
  background-color: #f6f6f6;
}

.sideContainer {
  @media screen and (min-width: 576px) {
  }
  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 992px) {
    min-width: 240px;
    max-width: 250px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 295px;
  }
}

.designName {
  &::placeholder {
    text-align: center;
  }
}
input[type='radio'][disabled] {
  border: none;
  opacity: 0.7;
  filter: grayscale(80%);
}

.previewImg {
  height: 50px;
  width: 66.5px;
  object-fit: contain;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: vars.$color-light;
}

.inputfile {
  display: none;
}
textarea {
  text-align: start;
  position: relative;
  z-index: 1;
  height: 150px;
  padding: 1rem 1rem !important;
}
.removeButton {
  border-radius: 8px;
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: vars.$color-danger;
  color: vars.$color-light;
  font-family: vars.$font-heading;
  font-weight: 700;
  font-size: 1.4rem;

  &:hover {
    cursor: pointer;
    color: vars.$color-light !important;
    background-color: lighten(vars.$color-danger, 20%) !important;
  }
}

textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  position: relative;

  // border: 1px solid red;
  top: 45%;
}

// ::-webkit-input-placeholder {
//   display: none;
//   // align-items: center;
//   // justify-content: center;
// }

// :-moz-placeholder {
//   /* Firefox 18- */
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// :-ms-input-placeholder {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.dropcontainercoverimages {
  height: 100px;
  border-radius: 10px;
  border: 2px dashed #7997c5;
  color: #444;
  cursor: pointer;
  background-color: white;
}

.dropcontainerimages {
  height: 200px;
  border-radius: 10px;
  border: 2px dashed #7997c5;
  color: #444;
  cursor: pointer;
  background-color: white;
}

.dropcontainerarchive {
  height: 40px;
  border-radius: 10px;

  color: #444;
  cursor: pointer;
  background-color: white;
}

.closeButton {
  height: 30px;
  width: 30px;
  background-color: vars.$color-danger;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.closeButton:hover {
  opacity: 0.7;
}

.uploadCoverContainer {
  height: 200px;

  .dropzone {
    border: 0.2rem dashed vars.$color-desall-blue;
    border-radius: 8px;
    margin: 0;

    flex-grow: 1;
    width: 100%;
    height: 100%;
  }
}
.uploadImagesContainer {
  height: 300px;

  .dropzone {
    border: 0.2rem dashed vars.$color-desall-blue;
    border-radius: 8px;
    margin: 0;

    flex-grow: 1;
    width: 100%;
    height: 100%;
  }
}

.suggestionWrapper {
  // border: 1px solid red;
  height: 300px;
  position: absolute;
  width: 300px;
  border-radius: 8px;
  background-color: vars.$color-light;
  z-index: 3;
  overflow: scroll;
  .suggestionRow {
    height: 30px;
    // border: 1px solid blue;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 15px;
  }
}

.arrow {
  display: flex;
  margin-right: 3rem;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;

  .angle {
    // border: 1px solid black;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.transitionIn {
  animation-duration: 0.2s;
  animation-name: animationIn;
}

@keyframes animationIn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.filezip {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.progressBarWrapper {
  height: 31px;
  width: 100%;
  border-radius: vars.$radius-s;
  background-color: vars.$color-light-gray-2;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: start;
  text-align: center;
  font-family: vars.$font-heading;
  font-weight: 700;
  font-size: 1.4rem;
  color: vars.$color-light;

  .progressBar {
    @include mixins.transitionall(0.5s);
    transition-delay: 0.5s;
    height: 100%;
    background-color: vars.$color-desall-blue;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    span {
      position: absolute;
      // border: 1px solid red;
      margin-left: 14.5rem;
      text-transform: uppercase;
    }
  }
}

.message {
  @include mixins.transitionall(0.2s);
  opacity: 1;
  z-index: 10;

  .freccetta {
    height: 90px;
    width: 50px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: vars.$color-light-gray-2;
    left: 16rem;
    top: -22px;
    // border: 1px solid red;
    transform: rotate(-45deg);
  }
  .infoActions {
    display: flex;
    border-radius: 8px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 130px;
    left: 14.3rem;
    top: -57px;
    width: 450px;
    // border-radius: 8px ;

    // top: 900px;

    background-color: vars.$color-light-gray-2;

    position: absolute;
  }
}
.noMessage {
  @include mixins.transitionall(0.2s);
  opacity: 0;
  visibility: hidden;

  .freccetta {
    height: 90px;
    width: 50px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: vars.$color-light-gray-2;
    left: 16rem;
    top: -22px;
    // border: 1px solid red;
    transform: rotate(-45deg);
  }
  .infoActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    height: 130px;
    left: 14.5rem;
    top: -57px;
    width: 450px;

    // top: 900px;

    background-color: vars.$color-light-gray-2;

    position: absolute;
  }
}

.messageDown {
  @include mixins.transitionall(0.2s);
  opacity: 1;
  z-index: 10;

  .freccetta {
    height: 30px;
    width: 50px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: vars.$color-light;
    left: 12.5rem;
    top: 50px;
    // border: 1px solid red;
    transform: rotate(-45deg);
    // border: 1px solid black;
  }
  .infoActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    height: 60px;
    left: 0rem;
    top: 50px;
    width: 450px;
    border-radius: 8px;
    // border: 1px solid black;
    // border-radius: 8px ;

    // top: 900px;

    background-color: vars.$color-light;

    position: absolute;
  }
}

.noMessageDown {
  @include mixins.transitionall(0.2s);
  opacity: 1;
  visibility: hidden;

  .freccetta {
    height: 30px;
    width: 50px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: vars.$color-light;
    left: 12.5rem;
    top: 50px;
    // border: 1px solid red;
    transform: rotate(-45deg);
    // border: 1px solid black;
  }
  .infoActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    height: 60px;
    left: 0rem;
    top: 50px;
    width: 450px;
    border-radius: 8px;
    // border: 1px solid black;
    // border-radius: 8px ;

    // top: 900px;

    background-color: vars.$color-light;

    position: absolute;
  }
}

.toggle {
  @include mixins.transitionall(0.2s);
  position: relative;

  background-color: vars.$color-desall-blue;
  border-radius: vars.$radius-m;
  height: 30px;
  line-height: 1.2rem;
  padding: 0 1rem;
  font-size: vars.$font-size-xs;
  color: vars.$color-dark;

  display: flex;
  align-items: center;

  & .dropdown__label {
    padding: 0.4rem 0;
    overflow-y: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 2rem;
  }
}

.arrow {
  position: relative;
  @include mixins.transitionall(0.2s);
  display: inline-block;
  padding: 3px;
  // border: 1px solid red;

  position: absolute;
  right: 0.15rem;
  top: -0.2rem;

  width: 2.2rem;
  height: 2.2rem;
  // background-image: url("data:image/svg+xml;utf8,<svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='m17.488 8.53-5.979 5.979a.894.894 0 0 1-1.268 0L4.263 8.531A.896.896 0 1 1 5.53 7.263l5.344 5.344 5.344-5.344a.897.897 0 1 1 1.269 1.268z' fill='%23193A56' fill-rule='evenodd'/></svg>");
  // background-repeat: no-repeat;

  &::after {
    @include mixins.transitionall(0.2s);
    position: absolute;
    display: block;
    content: '';
    width: 1.2rem;
    height: 1.2rem;
    border: 0.23rem solid vars.$color-light;
    background: vars.$color-desall-blue;
    border-top: 0;
    border-left: 0;
    right: 1rem;
    top: 0.75rem;
    transform: rotate(45deg);
    cursor: pointer;
  }
}
.open {
  .arrow {
    top: 0.3rem;
    &::after {
      transform: rotate(-135deg);
    }
  }
}
