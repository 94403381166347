@use '../../../scss/variables' as vars;

.cardInfoWrapper {
  border-radius: vars.$radius-s;
  overflow: hidden;
  align-self: stretch;
  min-height: 100%;
  height: 270px;
  width: 270px;
  
  
  // border: 1px solid red;

  .cardInfoImage {
    
    img {
      object-fit: cover;
      width: 100%;
      // height: 25rem;
    }
  }

  .cardImagePlaceholder {
    //height: 27.5rem;
    background-color: vars.$color-light-gray-4;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8rem;
  }

  .cardInfoContent {
    padding: 1rem;
  }

  .squaredImageWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;
    overflow: hidden;
    width: 100%;

    .imageInner {
      min-width: 100%;
      aspect-ratio: 1 / 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      // border: 1px solid red;
      background-color: vars.$color-light_grey_15;
    }
  }
}
