.imagecontainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 30vh;
  .content { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 3;
  }
  img {
    width: 100%;

    object-fit: cover;
    height: 100%;
    z-index: 1;
  }
}
